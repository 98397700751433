import React from "react"
import Head from '../components/head'
import Layout from '../components/layout'
import AniText from '../components/anitext'

const index = () => {
    return (
        <Layout>
            <Head title="Home"/>
            <AniText/>
        </Layout>
    )
} 

export default index;