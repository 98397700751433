import React from "react"
import {anitext, commandline, cover} from './anitext.module.scss'

const AniText = () => {
    return (
        <div className={anitext}>
            <div>
            <span className={commandline}>Nikos Katomeris&nbsp;
                <span className={cover} nletters={32}>&nbsp;</span>
            </span>
            </div>
            <div>
            <span className={commandline}>nicktheway&nbsp;
                <span className={cover} nletters={22}>&nbsp;</span>
            </span>
            </div>
        </div>
    )
}


export default AniText 